/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Button,
    Row,
    Col,
    Card,
    Divider,
    Image,
} from 'antd';
import {
    ActivityIcon,
    ArrowRight,
    CalendarIcon,
    HotelIcon,
    LocationIcon,
    ShipIcon,
    TourTypeIcon,
} from '../../assets/images/icons';
import decodeHtml from '../../helpers/decodeHtml';

function getCategory(category) {
    if (category === 'Activity') {
        return 'activity';
    }
    if (category === '1') {
        return 'trip';
    }
    if (category === '2') {
        return 'package';
    }
    if (category === '3') {
        return 'cruise';
    }
    return '';
}

function GridMode({ data, service }) {
    const { t } = useTranslation();
    const loading = false;

    const viewItemDetails = (itemId, itemCategory) => {
        const searchParams = new URLSearchParams();
        if (service === '1') {
            searchParams.append('widget-type', 'accommodations');
            searchParams.append('widget-id', itemId);
        } else {
            searchParams.append('widget-type', 'tours');
            searchParams.append('widget-tour-type', getCategory(itemCategory));
            searchParams.append('widget-id', itemId);
        }
        const url = new URL(`https://${window.location.host}/widget-results`);
        url.search = searchParams.toString();
        window.location.href = url.toString();
    };

    return (
        <div className="hola-grid-content-wrapper">
            <div className="hola-service-list-card">
                {loading ? 'Loading...' : (
                    <Row
                        gutter={[25, 25]}
                    >
                        {data?.map((item) => (
                            <Col key={item.id}>
                                <Card
                                    cover={(
                                        <>
                                            <div className="more-details-top">
                                                <div className={clsx(item.discount && 'discount')}>
                                                    {item.discount && `-${item.discount}${item.discount_type} Discount`}
                                                </div>
                                                {item.hotel && <div className="hotel">{item.hotel}</div>}
                                            </div>
                                            <Image
                                                alt={`Image ${item.id}`}
                                                src={item.image}
                                                preview={false}
                                            />
                                            <div className="more-details-bottom">
                                                <div>
                                                    <LocationIcon />
                                                    {item.location}
                                                </div>
                                                <div>
                                                    {item.category === '3' && <ShipIcon />}
                                                    {(item.category === '2'
                                                    || item.category === '1') && <TourTypeIcon />}
                                                    {item.category === 'Activity' && <ActivityIcon />}
                                                    {item.category === '1' && t('Trip Tour')}
                                                    {item.category === '2' && t('Tour Package')}
                                                    {item.category === '3' && t('Cruise')}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                >
                                    <Card.Meta
                                        title={item.name}
                                        description={(
                                            <div className="card-description">
                                                <div className="item-description">
                                                    <div className="item-itenerary-duration">
                                                        <div>
                                                            {item.category === '2' ? <HotelIcon /> : <LocationIcon />}
                                                            {item.category === '2' ? `${item.hotel} ${item.hotel_location}` : `${item.location}`}
                                                        </div>
                                                        {item.category !== '1' && service !== '1' && (
                                                            <div>
                                                                <CalendarIcon />
                                                                {item.category === '2'
                                                                    ? `${item.duration} ${item.duration_type}`
                                                                    : `${item.start_date} - ${item.end_date}`}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div dangerouslySetInnerHTML={{ __html: decodeHtml(item.description) }} />
                                                </div>
                                                <Divider />
                                                <div className="item-price">
                                                    <div>
                                                        {item.category === '1' ? (
                                                            <div>{`${t('Group')} ${t('Tour')}`}</div>
                                                        ) : (
                                                            <div>{`${t('Starting from')}`}</div>
                                                        )}
                                                        <div className="price-value">
                                                            {`${item.currency_icon}${item.adult_price} / ${item.price_type}`}
                                                        </div>
                                                    </div>
                                                    <Button
                                                        type="primary"
                                                        className="view-button"
                                                        onClick={() => viewItemDetails(item.id, item.category)}
                                                    >
                                                        {t('Book Now').toUpperCase()}
                                                        <ArrowRight />
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </div>
        </div>
    );
}

export default GridMode;
