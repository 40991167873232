export default {
    en: {
        translation: {
            Discount: 'Discount',
            nights: 'nights',
            person: 'person',
            from: 'from',
            Tour: 'Tour',
            Group: 'Group',
            'Starting from': 'Starting from',
            'Book Now': 'Book Now',
            Activity: 'Ativity',
            'Trip Tour': 'Trip Tour',
            'Tour Package': 'Tour Package',
            'Cruise Tour': 'Cruise Tour',
            'Group Tour': 'Group Tour',
            Cruise: 'Cruise',
        },
    },
    it: {
        translation: {
            Discount: 'Sconto',
            nights: 'notti',
            person: 'persona',
            from: 'da',
            Tour: 'Tour',
            Group: 'Gruppo',
            'Starting from': 'A partire da',
            'Book Now': 'Prenota ora',
            Activity: 'Attività',
            'Trip Tour': 'Tour',
            'Tour Package': 'Pacchetto',
            'Cruise Tour': 'Crociere',
            'Group Tour': 'Gruppo',
            Cruise: 'Crociere',
        },
    },
};
