import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import resources from './config/translation';

const reactAppUrl = process.env.REACT_APP_URL;

const setLanguageFileUrl = (language: string[], namespace: string) => {
    const languageFamily = language[0];
    return `${reactAppUrl}/locales/${languageFamily}/${namespace}.json`;
};

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        load: 'languageOnly',
        resources,
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: (language, namespace) => setLanguageFileUrl(language, namespace),
        },
        fallbackLng: ['sq', 'en', 'es'],
        debug: false,
        react: {
            useSuspense: true,
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
