import { useTranslation } from 'react-i18next';
import { useWindowWidth } from '@react-hook/window-size';
import Carousel from 'react-elastic-carousel';
import { Image, Space } from 'antd';
import { LocationIcon } from '../../assets/images/icons';

function getCategory(category) {
    if (category === 'Activity') {
        return 'activity';
    }
    if (category === '1') {
        return 'trip';
    }
    if (category === '2') {
        return 'package';
    }
    if (category === '3') {
        return 'cruise';
    }
    return '';
}

function CarouselMode({ data, service }) {
    const { t } = useTranslation();
    const windowWidth = useWindowWidth({ wait: 50 });

    const viewServiceDetails = (itemId, itemCategory) => {
        const searchParams = new URLSearchParams();
        if (service === '1') {
            searchParams.append('widget-type', 'accommodations');
            searchParams.append('widget-id', itemId);
        } else {
            searchParams.append('widget-type', 'tours');
            searchParams.append('widget-tour-type', getCategory(itemCategory));
            searchParams.append('widget-id', itemId);
        }
        const url = new URL(`https://${window.location.host}/widget-results`);
        url.search = searchParams.toString();
        window.location.href = url.toString();
    };

    return (
        <Carousel
            className="hola-carousel"
            {...(windowWidth < 768 && { itemsToShow: 1 })}
            {...(windowWidth < 1366 && windowWidth > 768 && { itemsToShow: 2 })}
            {...(windowWidth >= 1366 && { itemsToShow: 3 })}
            itemPadding={[0, 10, 0, 10]}
            enableMouseSwipe={false}
            pagination={false}
        >
            {data?.map((item) => (
                <div
                    key={item.id}
                    className="hola-carousel-item"
                    tabIndex={0}
                    onClick={() => viewServiceDetails(item.id, item.category)}
                    role="button"
                >
                    <Image
                        src={item.image}
                        alt={`Image ${item.id}`}
                        preview={false}
                    />
                    <div className="hola-c-item-content-top">
                        <Space size={45}>
                            <div className="hola-location">
                                <LocationIcon />
                                <span>{item.location}</span>
                            </div>
                            <div className="hola-discount">
                                {item.discount && `-${item.discount}${item.discount_type} Discount`}
                            </div>
                        </Space>
                    </div>
                    <div className="hola-c-item-content-bottom">
                        <div className="hola-tour-category">
                            {item.category === 'Activity' && t('Activity')}
                            {item.category === '1' && t('Trip Tour')}
                            {item.category === '2' && `${t('Tour Package')} | ${item.hotel_name}`}
                            {item.category === '3' && t('Cruise Tour')}
                        </div>
                        <div className="hola-tour-title">{item.name}</div>
                        <div className="hola-tour-price">
                            <span>
                                {item.category === '1' && t('Group Tour')}
                                {item.category === '2' && `${item.duration} ${t('nights')}`}
                                {item.category === '3' && `${item.start_date} - ${item.end_date}`}
                            </span>
                            <span>|</span>
                            <span>
                                {item.category === '1' && `${item.currency_icon}${item.adult_price} / ${t(item.price_type)}`}
                                {(item.category === '2' || item.category === '3') && `${t('from')} $${item.adult_price}`}
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    );
}

export default CarouselMode;
