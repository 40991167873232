import React from 'react';
import WidgetComponent from './widgetComponent';

function App({ data }) {
    return (
        <WidgetComponent divId={data} />
    );
}

export default App;
