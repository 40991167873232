import React, { useEffect, useState } from 'react';
import { Buffer } from 'buffer';
import { Space } from 'antd';
import GridMode from './Grid';
import CarouselMode from './Carousel';

function WidgetComponent({ divId }) {
    const buffer = Buffer.from(divId, 'base64');
    const text = buffer.toString('ascii');
    const array = text.split('_');

    // card type
    const typeArray = array[0].split('=');
    const cardType = typeArray[1];

    // services
    const serviceArray = array[1].split('=');
    const service = serviceArray[1];

    // tour type
    const tourTypeArray = service === '1' ? [] : array[2].split('=');
    const tourTypes = tourTypeArray[1]?.split(',');

    // category
    const categoryArray = service === '1' ? array[2].split('=') : array[3].split('=');
    const categories = categoryArray[1].split(',');

    // token
    const tokenArray = service === '1' ? array[3].split('=') : array[4].split('=');
    const tokenValue = tokenArray[1];

    const [loading, setLoading] = useState(false);
    const [widgetData, setWidgetData] = useState([]);

    // fetch data
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const requestData = {
                    widgets_type: [service],
                    widgets_tour: tourTypes,
                    widgets_category: categories,
                    token: tokenValue,
                };

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestData),
                };

                const response = await fetch(process.env.REACT_APP_API_URL, requestOptions);
                const data = await response.json();
                !data.error && setWidgetData(data.data);

                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        loading ? 'Loading...' : (
            <div className="hola-widget-wrapper">
                <Space direction="vertical" size={50}>
                    {cardType === '0'
                        ? <GridMode data={widgetData} service={service} />
                        : <CarouselMode data={widgetData} service={service} />}
                </Space>
            </div>
        )
    );
}

export default WidgetComponent;
