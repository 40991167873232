import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Buffer } from 'buffer';
import { ConfigProvider } from 'antd';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './style/main.scss';
import './style-antd/main.less';

const isDomainAllowed = (data, currentDomain) => {
    const buffer = Buffer.from(data, 'base64');
    const text = buffer.toString('ascii');
    const array = text.split('_');
    const servicesArray = array[1].split('=');
    const service = servicesArray[1];
    const whiteListArray = service === '1' ? array[4]?.split('=') : array[5]?.split('=');
    const allowedDomains = whiteListArray[1];

    return allowedDomains.includes(currentDomain);
};

// Get the current domain
const currentDomain = window.location.hostname.replace(/^www\./, '');

// Check if the current domain is in the whitelist
const elements = document.getElementsByClassName('root-custom');
for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const root = ReactDOMClient.createRoot(element);
    if (isDomainAllowed(element.id, currentDomain)) {
        root.render(
            <ConfigProvider>
                <App data={element.id} />
            </ConfigProvider>,
        );
    } else {
        // console.log('Widget not allowed on this domain.');
        root.render(
            <div>
                <p>
                    {`Widget ${i + 1} not allowed on this domain`}
                </p>
            </div>,
        );
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
